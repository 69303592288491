import PropTypes from 'prop-types';
import ProductTile from 'components/ProductTile';
import { ProductTileCollections } from '../ProductTileCollections';

const CustomCollection = (props) => {
  const {
    products,
    productIds,
    highResImages,
    USE_COLLECTIONS
  } = props;

  const filteredProducts = products.filter(product => productIds.includes(product.id));

  let customTiles = [];

  if (filteredProducts.length) {
    customTiles = filteredProducts.map((product, position) => {
      if (USE_COLLECTIONS) {
        return <ProductTileCollections product={ product } key={ product.id } list="Featured Products" position={ position } />;
      } else {
        return <ProductTile product={ product } highResImages={ highResImages } key={ product.id } list="Featured Products" position={ position } />;
      }
    });
  }

  return (
    <>
      { customTiles }
    </>
  );
};

CustomCollection.propTypes = {
  products: PropTypes.array.isRequired,
  productIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  highResImages: PropTypes.bool,
  USE_COLLECTIONS: PropTypes.bool.isRequired
};

CustomCollection.defaultProps = {
  highResImages: false
};

export default CustomCollection;
